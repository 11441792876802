import React from 'react';
import '../styles/trainings.css'
const TrainingProgramsPage = () => {
  const plans = [
    {
      id: 1,
      title: 'Programming in Python',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
      logo: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/267_Python_logo-512.png  ',
    },
    {
      id: 2,
      title: 'Harware and IOT',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
      logo: 'https://olimex.files.wordpress.com/2017/06/arduino-logo-circle-thumb.png?w=584',
    },
    {
      id: 3,
      title: '3D modelling and Designing',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/2503px-Blender_logo_no_text.svg.png',
    },
    {
      id: 4,
      title: 'Career Development',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
      logo: 'https://www.pngmart.com/files/22/Target-Logos-PNG-Photos.png',
    },
  ];

  return (
    <div className='training'>
    <div className="training-plans">
      <h1>Trainings</h1>
      <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.id} className="plan-card">
            <img src={plan.logo} alt={plan.title} />
            <h2>{plan.title}</h2>
            <h3>{plan.price}</h3>
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <button>View More</button>
          </div>
        ))}
      </div>
    </div>
    
    </div>
  );
};

export default TrainingProgramsPage;
