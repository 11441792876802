import React from 'react';
import '../styles/error.css'
import Oops from '../assets/images/oops.png'

function ErrorPage() {
  return (
    <div className='error-page'>
        <img src={Oops} alt="Error" />
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
    </div>
  );
}

export default ErrorPage;
