import React from 'react'
import Navigation from './components/Navigation'
import HomePage from './pages/HomePage.js'
import GalleryPage from './pages/Gallery'

import {BrowserRouter , Route , Switch } from 'react-router-dom';
import TrainingProgramsPage from './pages/TrainingProgramsPage';
import TeamList from './pages/TeamList';
import ErrorPage from './pages/ErrorPage';


function App() {
  return (
    <div className='app'>
        <Navigation/>
      <BrowserRouter>
      <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/training" component={TrainingProgramsPage} />
          <Route path="/gallery" component={GalleryPage} />
          
          <Route path="/team" component={TeamList} />
          <Route path="*" component={ErrorPage} />
        
      </Switch>
    </BrowserRouter>
    </div>
  )
}

export default App