import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import '../styles/gallery.css';

const GalleryPage = () => {
  const [images, setImages] = useState([]);

  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    992: 2,
    768: 1,
  };

  useEffect(() => {
    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDh_uTfEvjAplIT9n9hH9eAusUinnAsDv0",
      authDomain: "corrbot-58096.firebaseapp.com",
      projectId: "corrbot-58096",
      storageBucket: "corrbot-58096.appspot.com",
      messagingSenderId: "391213775363",
      appId: "1:391213775363:web:5f7d19e370dda23a942c36",
      measurementId: "G-R70RX25068"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Get a reference to the Firebase storage bucket
    const storage = getStorage(app);
    const storageRef = ref(storage, 'images/gallery'); // Assuming your images are in 'images' folder

    console.log(storageRef);

    // Fetch image URLs from Firebase Storage
    const fetchImageUrls = async () => {
      const imageRefs = await listAll(storageRef);
      const imageList = await Promise.all(imageRefs.items.map(async (item, index) => {
        const url = await getDownloadURL(item);
        return {
          id: index + 1,
          src: url,
          title: `Image ${index + 1}`,
        };
      }));
      setImages(imageList);
    };

    fetchImageUrls();
  }, []);

  return (
    <div className="gallery">
      <h1>Gallery</h1>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="gallery-grid"
        columnClassName="gallery-grid-column"
      >
        {images.map((image) => (
          <div key={image.id} className="gallery-item">
            <img src={image.src} alt={image.title} />
            <div className="gallery-item-overlay">
              <h3>{image.title}</h3>
            </div>
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default GalleryPage;
