import '../styles/home.css'
import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';




export default function HomePage() {

  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submit button clicked');

    emailjs
      .send('service_d5mgjog', 'template_fby4lsf', formData, 'aCrQMz4kk-AW1FQ_o')
      .then((response) => {
        console.log('Email sent successfully!', response);
        setMessage('Message sent successfully.');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((error) => {
        console.error('Failed to send message:', error);
        setMessage('Failed to send message. Please try again later.');
      });
  };

  const customerLogos = [
    { id: 1, name: 'Company A', logoUrl: 'https://www.pngmart.com/files/23/Free-Logos-PNG-Isolated-Pic.png' },
    { id: 2, name: 'Company B', logoUrl: 'https://www.pngmart.com/files/23/Free-Logos-PNG.png' },
    { id: 3, name: 'Company C', logoUrl: 'https://www.pngmart.com/files/23/Free-Logos-PNG-Isolated-File.png' },
    { id: 3, name: 'Company D', logoUrl: 'https://www.pngmart.com/files/23/Free-Logos-PNG-HD-Isolated.png' },
    // Add more customer logos as needed
  ];

  const achievements = [
    {
      title: 'Innovation Grant 2022 - KSUM',
      description: '',
      image: 'https://img.onmanorama.com/content/dam/mm/en/news/business/images/2021/9/22/kerala-innovation-grant.jpg',
    },
    {
      title: 'Venture Challenge 6.0',
      description: 'Incubation at AIC Shiv Nadar University',
      image: 'https://s3.ap-south-1.amazonaws.com/townscript-production/images/952f3109-50db-48d3-8664-bf274ab04a53.jpg',
    },
    {
    title: 'Nidhi Prayas Grant 2023',
      description: '',
      image: 'https://ciic.ventures/wp-content/uploads/2023/05/nidhi_1.jpg',
  },
  {
    title: 'Patnered with IIMK LIVE 2023',
      description: '',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXR7j9DcE_f7pcxa6JSwcpUNsGhpXB_JgsBe5y1La18Q2JbZmoGihbDIDw7_8b1Wie9QE&usqp=CAU',
  }
  ];
  return (
    <div className='home'>
    <div className="landing-page">
        <div className="overlay">
  <h1 className="text">CORR ROBOTICS</h1>
  <p className="tagline">Building a Future with Robotics</p>
</div>

    </div>


    <div className="company-section" id='aboutus'>
        <h2>ABOUT OUR COMPANY</h2>
        <p>At CORR ROBOTICS, we are revolutionizing underwater inspections with cutting-edge robotics technology. Our mission is to enhance safety, efficiency, and precision in underwater environments by providing innovative solutions that address the challenges of traditional inspection methods. </p>
        <p>Driven by a passion for technological innovation and a commitment to excellence, our team of experts is dedicated to developing advanced underwater robotic systems that meet the evolving needs of industries such as marine exploration, infrastructure maintenance, offshore energy, environmental monitoring, and underwater research.With a focus on autonomy, compactness, and reliability, our flagship product, CORR ROV GEN 1, is designed to redefine underwater inspections. By leveraging state-of-the-art robotics, artificial intelligence, and sensor technologies, we empower our customers to streamline operations, reduce costs, and mitigate risks associated with underwater inspections.</p>
        <p>Join us in shaping the future of underwater exploration and inspection with CORR ROBOTICS.</p>
      </div>


 
      {/*<div className="services-section" id='services'>
        <h2>OUR SERVICES</h2>
        <div className="serv  ices-container">

          <div className="service">
            <h3>Robotic Solutions</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>

          *<div className="service">
            <h3>Automation Consulting</h3>
            <p>Nulla eget felis sem. Integer tincidunt dolor vel gravida facilisis.</p>
  </div>

          <div className="service">
            <h3>Training Programs</h3>
            <p>Quisque id ultricies enim. Cras non placerat mauris.</p>
  </div>

        </div>
      </div>*/}


      {/* <div className="customer-logos">
      <h3>OUR VALUED CUSTOMERS</h3>
      <div className="logo-grid">
        {customerLogos.map((customer) => (
          <div className="logo-item" key={customer.id}>
            <img src={customer.logoUrl} alt={customer.name} />
          </div>
        ))}
      </div>
    </div> */}

      {/* Achievements section  */}
    <div className="achievement-cards">
      <h2>OUR ACHIEVEMENTS</h2>
      <div className="cards-container">
        {achievements.map((achievement, index) => (
          <div className="card" key={index}>
            <img src={achievement.image} alt={achievement.title} className="standardized-image"/>
            <div className="card-content">
              <h3>{achievement.title}</h3>
              <p>{achievement.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>


    <div className="contact-us">
      <div className="company-details">
        <h2>CORR Robotics</h2> 
        {/* <p>Address: 123 Main Street, City, Country</p> */}
        <p>Email: corr.robotics@gmail.com</p>
      </div>
      <div className="contact-form" id='contact'>
        <h3>Feedback</h3>
        <form onSubmit={handleSubmit}>
        {/* Form inputs and labels */}
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      </div>
    </div>
    

    <footer>
      <div className="copyright">
        <p>&copy; 2023 CORR ROBOTICS. All rights reserved.</p>
      </div>
    </footer>

    </div>
  );
}