import React from 'react';
import '../styles/team.css';

function TeamList() {
  const teamMembers = [
    {
      name: 'ROSHAN SIRAJ',
      position: 'CEO',
      photo: 'https://firebasestorage.googleapis.com/v0/b/corrbot-58096.appspot.com/o/images%2Fprofile%2Froshan.jpeg?alt=media&token=9c2b60e7-2308-461f-a9fc-269c4e729f08',
      skills: ['Entrepreneurship', 'Buisness Analysis'],
      socialLinks: {
        linkedin: 'https://www.linkedin.com/in/roshan-siraj-8861r',
        mail: 'mailto:roshansiraj688@gmail.com',
        twitter: 'https://twitter.com/roshansiraj7/',
      },
    },
    {
      name: 'RIYAN J',
      position: 'COO',
      photo: 'https://firebasestorage.googleapis.com/v0/b/corrbot-58096.appspot.com/o/images%2Fprofile%2Friyan.jpeg?alt=media&token=87b0ea8c-f5e0-4b1d-b243-ec87d62bdcb7',
      skills: ['Operations', 'Risk Analysis'],
      socialLinks: {
        linkedin: 'https://www.linkedin.com/in/riyan-j',
        mail: 'mailto:riyanj1000@gmail.com',
        twitter: 'https://twitter.com/riyanj1000?t=V1_ZtaFlgTeU3vtsqlVjRQ&s=09',
      },
    },
    

    // Add more team members here
  ];

  const technicalTeam = [
    {
      name: 'ARUN K',
      position: 'CTO',
      photo: 'https://firebasestorage.googleapis.com/v0/b/corrbot-58096.appspot.com/o/images%2Fprofile%2Farun.jpeg?alt=media&token=115dd59f-dd1b-4f62-a387-c9a9b71e310f',
      skills: ['IoT & Hardware', 'CAM/CAD '],
      socialLinks: {
        linkedin: 'https://www.linkedin.com/in/arunkarooth',
        mail: 'mailto:arunkarooth6@gmail.com',
        twitter: 'https://www.twitter.com/',
      },
    },


  ]

  return (
    <div className='team'>
      <div className="team-page">
        <h1>Management</h1>
        <div className="team-cards">
          {teamMembers.map((member, index) => (
          <article className="team-card" key={index}>
          <div className="team-card__img"  style={{ backgroundImage: `url(${member.photo})` }}>
          </div>
          <a href="#" className="team-card_link">
             <div className="team-card__img--hover" style={{ backgroundImage: `url(${member.photo})` }}></div>
           </a>
          <div className="team-card__info">
            <span className="team-card__category">{member.position}</span>
            <h3 className="team-card__title">{member.name}</h3>
            <div className="skill">
            {member.skills.map((skill, skillIndex) => (
                    <span key={skillIndex} className="skill-tag">{skill}</span>
                  ))}
            </div>
          </div>
          <div class="social-link">{Object.keys(member.socialLinks).map((platform, platformIndex) => (
            <a
            key={platformIndex}
            href={member.socialLinks[platform]}
            target="_blank"
            rel="noopener noreferrer"
            className={`fa fa-${platform==='mail'?'envelope':platform}`}
          /> ))}
          </div>
        </article>
        ))}
        </div>
        <h1>Technical Team</h1>
        <div className="team-cards">
          {technicalTeam.map((member, index) => (
          <article className="team-card" key={index}>
          <div className="team-card__img"  style={{ backgroundImage: `url(${member.photo})` }}>
          </div>
          <a href="#" className="team-card_link">
             <div className="team-card__img--hover" style={{ backgroundImage: `url(${member.photo})` }}></div>
           </a>
          <div className="team-card__info">
            <span className="team-card__category">{member.position}</span>
            <h3 className="team-card__title">{member.name}</h3>
            <div className="skill">
            {member.skills.map((skill, skillIndex) => (
                    <span key={skillIndex} className="skill-tag">{skill}</span>
                  ))}
            </div>
          </div>
          <div class="social-link">{Object.keys(member.socialLinks).map((platform, platformIndex) => (
            <a
            key={platformIndex}
            href={member.socialLinks[platform]}
            target="_blank"
            rel="noopener noreferrer"
            className={`fa fa-${platform==='mail'?'envelope':platform}`}
          /> ))}
          </div>
        </article>
        ))}
        </div>
      </div>
    </div>
  );
};

export default TeamList;
