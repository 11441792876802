import React, { useState } from 'react';
import Logo from '../assets/logo/image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/nav.css';

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);

  const handleMenuClick = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
        <a href="/" className="navbar-link">
          <img className='logo' src={Logo} alt="Logo" />
          </a>
        </div>
        <ul className={isMobile ? 'navbar-menu active' : 'navbar-menu'}>
          <li className="navbar-item">
            <a href="/" className="navbar-link">
              Home
            </a>
          </li>
          {/* <li className="navbar-item">
            <a href="/training" className="navbar-link">
              Training
            </a>
          </li> */}
          <li className="navbar-item">
            <a href="/gallery" className="navbar-link">
              Gallery
            </a>
          </li>
          <li className="navbar-item">
            <a href="/team" className="navbar-link">
              Team
            </a>
          </li>
        </ul>
        <div className="navbar-toggle" onClick={handleMenuClick}>
          <FontAwesomeIcon icon={isMobile ? faTimes : faBars} />
        </div>
      </div>
    </nav>
  );
}


export default Navbar;
